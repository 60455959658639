import React from "react";
import FullLayout from "./layouts/FullLayout2.js";

function Admin() {
  return (
    <div>
      <FullLayout />
    </div>
  );
}

export default Admin;
